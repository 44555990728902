<template>
  <div>
    <slot />
    <LazyFooter />
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()

useHead({
  link: [
    {
      rel: 'canonical',
      href: 'https://sis-it.com' + route.path,
    },
  ],
})
</script>

<style scoped lang="scss">
.whatsapp-button {
  position: fixed;
  right: 20px;
  bottom: 0;
  padding: 10px;
  z-index: 99;
  opacity: 0.5;

  img {
    height: 50px;
    filter: drop-shadow(1px 1px 5px #6363632f);
  }
}

.whatsapp-button:hover {
  opacity: 1;
  cursor: pointer;
  img {
    filter: drop-shadow(5px 5px 10px #41414191);
    transition: filter 0.3s ease-in-out;
  }
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
</style>
